<template>
  <b-card-code
    title="Table Event"
    no-body
  >
    <b-card-body>
      <div class="d-flex justify-content-between  flex-wrap">

        <!-- sorting  -->
        <b-form-group
          label="Sort"
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="3"
          label-for="sortBySelect"
          class="mr-1 mb-md-0"
        >
          <b-input-group
            size="sm"
            class="mr-1 mb-md-0"
          >
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">
                  none
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>

        <!-- filter -->
        <b-form-group
          label="Filter"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>

    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(featured_image)="data">
        <b-card-img :src="data.value" />
      </template>

      <!-- <template #cell(youtube_live_link)="data">
        <router-link :src="data.value" />
      </template> -->

      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>

      <!-- <template #cell(created_by)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template> -->

      <template #cell(actions)="row">
        <b-button
          variant="flat-warning"
          :to="{ name: 'event-edit', params: { id: row.item.id } }"
          class="text-nowrap"
        >
          <feather-icon icon="Edit3Icon" />
        </b-button>
        <b-button
          variant="flat-danger"
          class="text-nowrap"
          @click="deleteEventData(row.item)"
        >
          <feather-icon icon="TrashIcon" />
        </b-button>
      </template>

      <template #cell(qr_code)="row">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="text-nowrap"
          variant="primary"
          target="_blank"
          @click="qrCodeData(row.item)"
        >
          Buka QR Code
        </b-button>
      </template>

    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>

    <!-- <template #code>
      {{ codeKitchenSink }}
    </template> -->
  </b-card-code>
</template>

<script>
import axios from 'axios'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCardImg, BTable, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody,
} from 'bootstrap-vue'

// import { codeKitchenSink } from './code'

export default {
  components: {
    BCardCode,
    BTable,
    // BAvatar,
    BCardImg,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    // BImg,
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'featured_image', label: 'Featured Image',
        },
        {
          key: 'title', label: 'Title',
        },
        {
          key: 'description', label: 'Description',
        },
        {
          key: 'service_by', label: 'Service',
        },
        {
          key: 'start_date', label: 'Start Date',
        },
        {
          key: 'end_date', label: 'End Date',
        },
        {
          key: 'start_time', label: 'Start Time',
        },
        {
          key: 'end_time', label: 'End Time',
        },
        {
          key: 'church.name', label: 'Church',
        },
        { key: 'qr_code', label: 'QR Code' },
        {
          key: 'location', label: 'Location',
        },
        {
          key: 'latitude', label: 'Latitude',
        },
        {
          key: 'longitude', label: 'Longitude',
        },
        {
          key: 'youtube_live_link', label: 'Youtube Live Link',
        },
        {
          key: 'code', label: 'Code',
        },
        {
          key: 'user.name', label: 'Created By',
        },
        { key: 'actions', label: 'Actions' },
      ],
      items: [],
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.getEventData()
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    editEventData() {
      // eslint-disable-next-line no-alert
      alert('Coming Soon!')
    },

    getEventData() {
      axios
        .get('/events', {
          params: {
            id: this.$route.params.id,
          },
        })
        .then(res => {
          this.items = res.data.data
          // Set the initial number of items
          this.totalRows = this.items.length
        })
      // eslint-disable-next-line no-console
        .catch(err => console.log(err))
    },
    qrCodeData(rowData) {
      const route = this.$router.resolve({ name: 'qr-code', params: { code: rowData.code } })
      window.open(route.href)
    },
    deleteEventData(rowData) {
      this.$swal({
        title: `Delete ${rowData.name}?`,
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.delete(`/events/${rowData.id}`).then(res => {
            if (res.status === 200) {
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Your data has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.getEventData()
            } else {
              this.$swal({
                title: 'Error',
                text: 'Please contact admin',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelled',
            text: 'Your data is safe :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
}
</script>
<style scoped>
.content {
width: 100px;
}
</style>
